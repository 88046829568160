<template>
  <div class="md-layout-item md-size-100">
    <UserTransactionsTable />
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  components: {
    UserTransactionsTable: () =>
      import("@/components/Tables/UserTransactionsTable"),
  },

  beforeRouteLeave(to, from, next) {
    this.initParams();
    next();
  },

  methods: {
    ...mapMutations({
      initParams: "transactions/INIT_PARAMS",
    }),
  },
};
</script>

<style lang="scss" scoped></style>